import { Box, Grid, Paper,Link, Typography } from "@mui/material";

const ProfileSite = () => {

    return (
        
       <>

            <Grid item >
                <Box >
                    <Typography variant="h6" component='h6' > Modern Personal Profile WebApp using SPA and APIs </Typography>
                    <Typography variant="subtitle1"  sx={{fontSize:12, fontStyle:'italic'}} > Date : June/July 2024 </Typography>
                </Box>
            </Grid>
            <Grid item>
                <Typography variant="subtitle1" paragraph> 
                <br/>
                    In my school basic computer science course work, I had learnt Html, CSS, Javascript and static Html pages to build a small static website and host it locally. 
                    I was curious to understand how big internet companies build and maintain such complex and dynamic apps? What technologies they use? What process they follow?
                    How do they deploy and change it regularly.
                    I did research on internet and found out there is more to it than that shows on the site. 

                    <p>
                    It inspired me to explore more, learn and apply hands-on how to build modern web app for my personal profile and host it on the internet.
                    I began to think what to include as features for my personal web site? I quickly formed a basic rough
                    draft on paper how the front page can look. I wanted it to introduce myself and make it feel like personal connection with the reader. 
                    I learnt about broader process that internet companies follow in building and maintaning web apps, called Agile Methodology.
                    It involves identifying business usecase, customer/personas needs, UX/UI design with user flows, 
                    building prototype, validations and then iteratively developing full web apps using a technology stack and iterative development.
                    </p>
                    <Typography variant="h6" paragraph> React JS - Component library</Typography>
                    <p>
                    I started by learning types of web app, concept of server rendering vs client side rendering. The concept of Single Page Application (SPA) is interesting and many sites are built on this concept. 
                    I began understanding SPAs and their dependency on APIs for data. I started small with building a simple frontend template app with ReactJS library, a popular SPA library opensourced by Meta. 
                    ReactJS is Javascript based framework that allows to mix Html, CSS and JS, to build React reusable component model that can be reused, customized and composed. Concept of virtual DOM was interesting and how it makes rendering on screen faster.
                    <br></br>
                    I spent good time to learn how to use React library, build & use components, and compose pages using components. Open source has so much to offer here. There are plenty of internet content to learn on this topic. I went in deep 
                    and started building small nuggets, incrementally adding new features.
                    React JS is javascript library. We can use Typescript which gives type safety concept in a dynamic programming language of Javascript. It was great to learn and apply.
                    </p>

                    <p>
                    I started with main page layout. Using Material UI open source CSS styling library makes it simpler to style ready components like Grid, Appbar, Avatar, Stack, etc, which are building blocks to create new Header component. 
                        <br></br>
                        <Box component='img'
                            src="/images/details/header-img.jpeg"
                            sx={{
                                height: 80,
                                width: 1200,
                                maxHeight: { xs: 50, md: 80 },
                                maxWidth: { xs: 400, md: 800 },
                                padding: 1
                            }}>
                            
                        </Box>
                        <br></br>
                        <Typography variant="h6" paragraph> Material UI - Open source component library</Typography>       
                         Slowly, I learnt how to use Material UI components and build composed applications. It took time, patience  and perseverance to learn, but it was exciting. 
                         First time the page will be requested from server and loaded on browser, where ReactJS is injected in the page and initialized the framework. It load the App composed 
                         with pages and components. UI components need to work with server APIs to fetch relevant data objects in JSON to dynamically built component view and pages. 
                         Next, I learnt to develop first REST API on NodeJs and Express framework to provide all experiments data. It will return JSON array with experiments to display. 
                          This API will be consumed by experiments component on UI which will render information in a dynamic cards for better view. 

                         <br></br>     
                         <Box component='img'
                            src="/images/details/experiments-img.jpeg"
                            sx={{
                                height: 300,
                                width: 800,
                                maxHeight: { xs: 100, md: 200 },
                                maxWidth: { xs: 250, md: 400 },
                                border:1,
                                padding: 2
                            }}>
                            
                        </Box>
                        <br></br>
                        I wanted to experiment how user information could be captured and saved in the backend. 
                        Added usecase for 'ContactMe' and 'Feedback' features & links to forms. 
                        ContactMe form is designed as React component. It will display the form, takes user input, validates data inputs, and once submitted, will call 
                        backend API. The form will be posted to a POST API which will extract data information
                        and save it to a file for now. It could be a database as next step. 
                        
                        
                        
                        <br></br>
                        <Box component='img'
                            src="/images/details/contactme-img.jpeg"
                            sx={{
                                height: 300,
                                width: 800,
                                maxHeight: { xs: 100, md: 200 },
                                maxWidth: { xs: 250, md: 400 },
                                border:1,
                                padding: 2
                            }}>
                            
                        </Box>
                        <br></br>
                        <Typography variant="h6" paragraph> APIs with Nodejs </Typography>
                        I learnt NodeJs and ExpressJs framework, implemented server controller exposing APIs. 
                        L APIs from NodeJs app, with different endpoints supporting UI data fetch. using Nodejs express api framework, 
                        I wrote simple APIs. There is so much to dig deep in there, may be some other time.
                        <br></br>
                        <Box component='img'
                            src="/images/details/api-server-img.jpeg"
                            sx={{
                                height: 300,
                                width: 800,
                                maxHeight: { xs: 100, md: 200 },
                                maxWidth: { xs: 250, md: 400 },
                                border:1,
                                padding: 2
                            }}>
                            
                        </Box>
                        <br></br>
                        <Typography variant="h6" paragraph> Deploy to Google Cloud</Typography>

                        Next I came to the question of how to deploy it to internet? After consulting and reading online, I came to understand that there are many options to deploy.
                        One of them is to deploy to Google cloud App Engine. Google cloud offers credits to use its services. I opened an account and learnt basics how to use 
                        App Engine. Its quite simple to use. Install gcloud sdk on your laptop, and using terminal issue commands to deploy the app. It need deployment descriptor configuration
                        for deployment. I also used storage bucket to store app engine static assets. 

                        <br></br>
                        With gcloud cli tool, I did run build with yarn runner, which compiles typescipt to javascript, does minification and other tasks and creates build folder with all assets.
                        I had to configure app.xml for telling cloud what type of app and what urls to map. Thats it.. run the command gcloud app deploy ./app.yaml, it take few mins to created instance on AppEngine.
                        App Enginer provides a url to load home page. 
                        <br></br>
                        
                        I needed a hosting domain to be front facing url. I bought a domain subscription with GoDaddy DNS provider. Had to learn and configure traffic routing both at GoDaddy and Google cloud. 
                        With that I could access my website <Link href="ankitwalishetti.com"> ankitwalishetti.com</Link> 
                        <br></br>
                         Hurray...!

                        <p>
                            <br></br>
                            It was great learning experience. I feel motivated to continue learning further. There are many things to learn further. 
                            I hope to add features and continue adding new projects I take on from time to time. 
                            <br></br>
                        </p>      

                        <p>
                         Thank you for reading..! 
                        
                        </p>
                      
                    </p>


                </Typography>
            </Grid>
      
     </>              
    )
}

export default ProfileSite;


