import { Box, Typography,Grid } from "@mui/material";

const RasberryRobot = () => {

    return (
        
       <>
        <Grid item >
                <Box >
                    <Typography variant="h6" component='h6' > Build Face-tracking Robot with Raspberry Pi & OpenCV </Typography>
                    <Typography variant="subtitle1"  sx={{fontSize:12, fontStyle:'italic'}} > Date : Oct 2024 </Typography>
                </Box>
            </Grid>
            <Grid item>
                <Typography variant="subtitle1" paragraph> 
                <br/>
                    In my Robotics engagement, I did assembly and programming to compete in VEX and FTC robotic competition. In my Microcontroller subject
                    learning Arudino capabilities and sensors generated interest in me to learn and do more in practice. 
                    Reflecting on automation done in EV cars like Tesla and othere, I was curious how driving could be remotely controlled and AI used to make full-self-driving possible. 

                    <p>
                    It motivated me to explore using my old Raspberry pi controller at home and do something meaning full. I researched on interenet for hours reading about Rasberry pi, sensors
                    servo motors, camera control CSI interface and other interfaces pi provides. 
                    I bought new Pi5 8GB controller, a adafruit Crickit HAT for pi, got motor assembly kit that comes with pan-tilt motors, and pi camera module. 
                    
                    </p>
                    <Typography variant="h6" paragraph> Beginning to Learn Pi and Interfaces</Typography>
                    <p>
                    I started with Pi5 setup with Rasbian OS, which is based on Linux but lighter. Pi has 40 GPIO pins for interfacing with input/output sensors.
                    I learnt about Crickit HAT and its capabilities providing connectors for servo motors, touch sensors, neo-pixels and other signal I/O pins. 
                    Settign up Crickit over Pi pins was interesting learning by troubleshooting. After long hous of experiments and reading got to know that Pi and Crickit both have micro-controller, but they can 
                    talk to each other using I2C protocol. There corresponding python library for ucing crickit functions like servo, touch, and motor controls. 
                    <br></br>
                    I took a basic course in Python and learnt better ways to organize code and functions for reuse and clarity. I wrote many programs experimenting new features through programming.
                    <br></br>
                    Below are some of the main projects I did on my free time... 
                
                    </p>

                    <p>
                    <Typography variant="h6" paragraph> Controlling movement of Robot car with Xbox controller joysticks</Typography>  

                        Pi has natural interface with Keyboards through USB port. How to take inputs from Xbox controler? Ah!, Using Bluetooth, we can add connection to Xbox controller
                        but taking input when stick is moved up & down needed some work. Controller is another device for pi and it will have a file under /usr/device/js0. 
                        So with opening that file and using loop to fetch bytes and then converting them to Event structure was tricky. Now we could see the event carrying information:
                        event.type representing action, event.number representing which movement on controller is triggered, i.e. Up or Down of stick. Then the event.number gives the depth of push ranging from -32976 to + 32976
                        I then calibrate depth to levels of speed of the motor which turns the wheel. 
                        <br></br>
                        With this both left stick we map to left motor, and right stick mapped to right motor. Taking the levels of depth, I mapped those actions to motor action for turning front or backwards.
                        <br></br>
                        This made both wheels controlled through controller for movement.
                        <br></br>
                         Here is the image and video.

                        <br></br>
                        <Box component='img'
                            src="/images/experiments/robot-car-pi.jpeg"
                            sx={{
                                height: 500,
                                width: 800,
                                padding: 1
                            }}>
                            
                        </Box>
                        <br></br>
                        <Typography variant="h6" paragraph> Control Camera and taking Photo with Keybord events</Typography>       
                         
                         Camera interface is another tricky part. I used new model of IMX708 Pi camera module. It has flat ribbon cable type interface to connect between camera prot
                         on Pi board and Camera itself. There is 15 pin to 22 pin convert cable to be used. 

                         I faced this challenge to use OpenCV camera library to capture videos and images. After hours of research online found out that they are incompatible in Pi5 version. 
                         One recommendation was to use Picamer2 library that bridges backend and helps to capture video, image and streaming. One interesting fact is that the images are capture 
                         as Numpy arrays, also called frames. 
                         <br></br>
                         OpenCV library has many features, loved to learn it. I had to use waitKey(1) function to wait for keyboard events
                         while capturing images / video, then imshow() will show the captured image. Its interesting behavior. 

                         Looping continuously over key input, capture image and display continously. When key board event of spacebar is given, 
                         handle the event to save the photo after custom writing message on the image on screen. 
                         <br></br>
                         This was fun experiment.. but lot of good learning..!
                         <br></br>     
                        
                        
                        <br></br>
                        <Typography variant="h6" paragraph> Facetracking by camera using OpenCV ML library </Typography>
                        
                        OpenCV has many good features for computer vision. It comes with pre-trained AI/ML models for identifying face in an image. 
                        These models are packages as XML files. I thought it would be great to draw a rectangle once face is identified and helps tracking. 
                        As image is captured, it also captures size and properties of the pixels. Using the grayscale image, recognizing contracts it can identify contours of the face.
                        OpenCV has library functions to draw over image frame. Calculating the center of face and size of face vertical and horizontally, I could draw rectangle covering the face.
                        <br></br>
                        This was exciting for me, and saw this in Tesla Robotaxi vision images identifying pedestrians. Similar thing..! 
                        <br></br>
                        Next, the idea to divide the screen in 3 zones centering on the face center. When face is moved outside center zone, a trigger to move 
                        servo motor towards face direction will do the face-tracking. Some mathematics there, but not bad..!
                        <br></br>
                         It was awesome, how cool to see your face tracked by camera.
                        <br></br>
                        
                        <Box component='img'
                            src="/images/experiments/pi-code.jpeg"
                            sx={{
                                height: 300,
                                width: 800,
                                
                                border:1,
                            
                            }}>
                            
                        </Box>
                        <br></br>
                        <br></br>
                        <Typography variant="h6" paragraph> Next Experiments.. </Typography>
                            
                        I am thrilled to do these experiments, its such a joy, learning and satisfying. Worth the spent time. 
                        <br></br>
                        I will continue to read, research and experiements new ideas building over this project. I am keen to learn about Vision Transformers and its applications. 
                        AI/ML is fast developing field and Computer Vision is advanced now. I will do research and continue learning...

                        <br></br>
                        
                        It was great learning experience. I have shared the code in my Github, in case you are interested.

                        <br></br>
                        
                        Thank you for reading..! 
                        
                      
                    </p>


                </Typography>
            </Grid>
     </>              
    )
}

export default RasberryRobot;

